import React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import styles from "./SignUp.module.scss";
import getColors from "../../../util/getColors";
import LinkTo from "../../elements/LinkTo";
import Spinner from "../../../svgs/Spinner";

const SignUp = ({theme}) => {
    const themeColors = getColors(theme);

    const url = "https://openavn.us4.list-manage.com/subscribe/post?u=c624b931e46a746faf44678a0&id=c62170c9ce";

    const MyForm = ({ status, message, onValidated }) => {
        let email;
        const submit = () => {
            if(email || email.value.indexOf("@") > -1 || !email.value) {
                onValidated({
                    EMAIL: email.value
                });
            }
        }

        const _handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                submit();
            }
        }

        return (
            <>
            <div className={styles.formContainer} style={{themeColors}}>
                {status !== "success" && (
                    <div className={`${styles.formWrapper} ${status === "sending" && styles.formWrapperLoading}`}>
                        <div className={styles.form}>
                            <div className={styles.textInputContainer}>
                                <input type="email" name="Email" id="email" placeholder="Enter your email address" onFocus={(e) => e.target.placeholder = ""} onKeyDown={_handleKeyDown} onBlur={(e) => e.target.placeholder = "Enter your email address"} required ref={node => (email = node)} className={styles.textInput} aria-label="Enter your email address" />
                            </div>

                            <button type="submit" className={`${styles.button} ${styles.buttonStyled} ${styles.buttonFilled} ${styles.buttonFooter} ${styles.buttonSecondary}`} onClick={submit}>
                                Sign up
                            </button>
                        </div>

                        <p className={`conditions ${styles.agreement}`}>
                            By signing up you agree with our <LinkTo url="http://staging.openavn.com/terms" external={true} target="_blank">Terms and Conditions</LinkTo> and <LinkTo url="http://staging.openavn.com/privacy" external={true} target="_blank">Privacy Policy</LinkTo>
                        </p>
                    </div>
                )}

                {status === "sending" && (
                    <div className={styles.loading}>
                        <div className={styles.loading__inner}>
                            <Spinner />
                        </div>
                    </div>
                )}
            </div>

            <div className="newsletter-signup-form-container__message-container">
                {status === "error" && status !== "sending" && (
                    <div className={`errors ${status === "error" ? "has-error" : ""}`} data-for="email">
                        <span className={styles.error}>
                            <p className="meta">
                                {(message.indexOf("already") > -1) ? `Too many attempts for this email address.` : 'Please enter valid email address.' }
                            </p>
                        </span>
                    </div>
                )}
            </div>
            
            {status === "success" && (
                <div className={"newsletter-signup-form-container__newsletter-signup-form"}>
                    <div className={"newsletter-signup-form-container__thank-you"}>
                        <p>Thank you for your interest in OpenAVN. We will keep you up to date with our latest news and products.</p>
                    </div>
                </div>
            )}
        </>
        );
    };
    const form = (
        <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
                <MyForm
                    status={status}
                    message={message}
                    onValidated={formData => subscribe(formData)}
                />
            )}
        />
    )

    return (
        <>
            {form}
        </>
    )
};

export default SignUp;