import React from "react";

const LogoIcon = () => {
    return (
        <svg width="28" height="32" viewBox="0 0 28 32" fill="none" xmlns="http://www.w3.org/2000/svg" className="logo-icon">
            <path d="M26.1958 7.19357L17.2802 12.3159C17.2301 12.3492 17.1634 12.3492 17.0966 12.3159L11.0694 8.84005C11.0193 8.80678 10.9525 8.80678 10.8857 8.84005L0.0834794 15.0601C0.0333918 15.0933 0 15.1432 0 15.2097V18.1036C0 18.2366 0.150263 18.3198 0.267134 18.2532L10.4349 12.3991C10.485 12.3658 10.5518 12.3658 10.6186 12.3991L16.6458 15.875C16.6959 15.9083 16.7627 15.9083 16.8294 15.875L27.6317 9.65497C27.6818 9.62171 27.7152 9.57181 27.7152 9.50529V8.05839C27.7152 7.99186 27.6818 7.94197 27.6317 7.90871L26.3795 7.17694C26.3127 7.16031 26.2459 7.16031 26.1958 7.19357Z" />
            <path d="M17.1133 19.4668L11.0694 15.9909C11.0193 15.9576 10.9525 15.9576 10.8857 15.9909L0.0834794 22.2109C0.0333918 22.2441 0 22.294 0 22.3606V23.8075C0 23.874 0.0333918 23.9239 0.0834794 23.9571L1.33567 24.6889C1.38576 24.7222 1.45254 24.7222 1.51932 24.6889L10.4349 19.5665C10.485 19.5333 10.5518 19.5333 10.6186 19.5665L16.6458 23.0424C16.6959 23.0757 16.7627 23.0757 16.8294 23.0424L27.6317 16.8224C27.6818 16.7892 27.7152 16.7393 27.7152 16.6727V15.126V13.7789C27.7152 13.6459 27.5649 13.5627 27.448 13.6292L17.2802 19.4834C17.2301 19.4834 17.1634 19.4834 17.1133 19.4668Z" />
            <path d="M10.6019 5.26373L16.6291 8.73963C16.6792 8.77289 16.746 8.77289 16.8128 8.73963L22.673 5.38015C22.7899 5.31363 22.7899 5.13069 22.673 5.06416L20.1519 3.61726C20.1018 3.584 20.0351 3.584 19.9683 3.61726L17.2802 5.16395C17.2302 5.19721 17.1634 5.19721 17.0966 5.16395L14.1247 3.45095C14.0078 3.38442 14.0078 3.20148 14.1247 3.13496L16.4621 1.78784C16.579 1.72132 16.579 1.53837 16.4621 1.47185L13.9411 0.0249466C13.891 -0.00831554 13.8242 -0.00831554 13.7574 0.0249466L0.0667838 7.89144C0.033392 7.94134 0 8.00786 0 8.07439V10.9682C0 11.1012 0.150263 11.1844 0.267134 11.1179L10.4349 5.26373C10.485 5.23047 10.5518 5.23047 10.6019 5.26373Z" />
            <path d="M17.1135 26.6015L11.0696 23.1422C11.0195 23.1089 10.9527 23.1089 10.886 23.1422L5.0257 26.5017C4.90883 26.5682 4.90883 26.7511 5.0257 26.8177L7.54678 28.2646C7.59687 28.2978 7.66365 28.2978 7.73044 28.2646L10.4185 26.7179C10.4686 26.6846 10.5353 26.6846 10.6021 26.7179L13.574 28.4309C13.6909 28.4974 13.6909 28.6803 13.574 28.7469L11.2366 30.094C11.1197 30.1605 11.1197 30.3435 11.2366 30.41L13.7577 31.8569C13.8077 31.8901 13.8745 31.8901 13.9413 31.8569L27.6319 23.9904C27.682 23.9571 27.7154 23.9072 27.7154 23.8407V22.294V20.9469C27.7154 20.8138 27.5651 20.7307 27.4483 20.7972L17.2805 26.6514C17.2304 26.6347 17.1636 26.6347 17.1135 26.6015Z" />
        </svg>
    );
};

export default LogoIcon;