import React, { useState, useEffect } from "react";
import styles from "./LogoAnimated.module.scss";

const LogoAnimated = () => {
    const [startAnimation, setStartAnimation] = useState(false);

    useEffect(() => {
        setStartAnimation(true);
    }, []);

    return (
        <svg className={`${styles.logo} ${startAnimation ? styles.logoAnimated : ""}`} width="210" height="54" viewBox="0 0 210 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g className={`${styles.logo}`}>
                <path className={styles.line}d="M17.9548 8.91436L28.1621 14.8009C28.2469 14.8573 28.36 14.8573 28.4731 14.8009L38.3977 9.11152C38.5956 8.99886 38.5956 8.68904 38.3977 8.57638L34.1282 6.12598C34.0433 6.06965 33.9302 6.06965 33.8171 6.12598L29.2648 8.74537C29.18 8.8017 29.0669 8.8017 28.9538 8.74537L23.9208 5.84433C23.7229 5.73167 23.7229 5.42185 23.9208 5.30919L27.8793 3.02778C28.0773 2.91512 28.0773 2.6053 27.8793 2.49264L23.6098 0.0422482C23.525 -0.0140827 23.4119 -0.0140827 23.2988 0.0422482L0.113101 13.3645C0.0565508 13.449 0 13.5617 0 13.6743V18.5751C0 18.8004 0.254476 18.9413 0.452403 18.8286L17.672 8.91436C17.7568 8.85803 17.8699 8.85803 17.9548 8.91436Z" />
                <path className={styles.line} d="M44.3638 12.1814L29.2648 20.8564C29.18 20.9127 29.0669 20.9127 28.9538 20.8564L18.7465 14.9698C18.6616 14.9135 18.5485 14.9135 18.4354 14.9698L0.141376 25.5037C0.0565504 25.56 0 25.6445 0 25.7572V30.658C0 30.8833 0.254476 31.0241 0.452403 30.9114L17.672 20.9972C17.7568 20.9409 17.8699 20.9409 17.983 20.9972L28.1904 26.8838C28.2752 26.9401 28.3883 26.9401 28.5014 26.8838L46.7954 16.3499C46.8803 16.2936 46.9368 16.2091 46.9368 16.0964V13.646C46.9368 13.5334 46.8803 13.4489 46.7954 13.3925L44.6748 12.1533C44.5617 12.1251 44.4486 12.1251 44.3638 12.1814Z" />
                <path className={styles.line} d="M28.9821 32.9677L18.7465 27.0811C18.6616 27.0248 18.5485 27.0248 18.4354 27.0811L0.141376 37.615C0.0565504 37.6713 0 37.7558 0 37.8685V40.3189C0 40.4315 0.0565504 40.516 0.141376 40.5724L2.26202 41.8117C2.34684 41.868 2.45994 41.868 2.57304 41.8117L17.672 33.1367C17.7568 33.0804 17.8699 33.0804 17.983 33.1367L28.1904 39.0233C28.2752 39.0796 28.3883 39.0796 28.5014 39.0233L46.7954 28.4894C46.8803 28.4331 46.9368 28.3486 46.9368 28.2359V25.6165V23.3351C46.9368 23.1098 46.6823 22.969 46.4844 23.0816L29.2648 32.9959C29.18 32.9959 29.0669 32.9959 28.9821 32.9677Z" />
                <path className={styles.line} d="M28.983 45.0507L18.7473 39.1923C18.6625 39.1359 18.5494 39.1359 18.4363 39.1923L8.51173 44.8817C8.3138 44.9943 8.3138 45.3042 8.51173 45.4168L12.7813 47.8672C12.8661 47.9235 12.9792 47.9235 13.0923 47.8672L17.6446 45.2478C17.7294 45.1915 17.8425 45.1915 17.9556 45.2478L22.9886 48.1489C23.1866 48.2615 23.1866 48.5713 22.9886 48.684L19.0301 50.9654C18.8322 51.0781 18.8322 51.3879 19.0301 51.5006L23.2997 53.9509C23.3845 54.0073 23.4976 54.0073 23.6107 53.9509L46.7963 40.6287C46.8812 40.5724 46.9377 40.4879 46.9377 40.3752V37.7558V35.4744C46.9377 35.2491 46.6832 35.1083 46.4853 35.2209L29.2657 45.1352C29.1809 45.107 29.0678 45.107 28.983 45.0507Z" />
            </g>
            <g className={styles.logotype}>
                <path className={styles.letter} d="M75.9765 31.9819C75.9765 35.925 73.5165 39.1359 67.3808 39.1359C61.2451 39.1359 58.7852 35.8968 58.7852 31.9819V21.9831C58.7852 18.04 61.2451 14.8291 67.3808 14.8291C73.5165 14.8291 75.9765 18.0681 75.9765 21.9831V31.9819ZM61.8672 31.9255C61.8672 34.8266 63.6485 36.4602 67.3808 36.4602C71.1131 36.4602 72.8945 34.8266 72.8945 31.9255V22.0676C72.8945 19.1666 71.1131 17.533 67.3808 17.533C63.6485 17.533 61.8672 19.1666 61.8672 22.0676V31.9255Z" />
                
                <path className={styles.letter} d="M81.9707 15.167H90.5664C95.6559 15.167 97.4372 17.3639 97.4372 20.9972V24.5179C97.4372 28.1794 95.6559 30.3763 90.5664 30.3763H85.0527V38.826H81.999V15.167H81.9707ZM90.4533 17.899H85.0527V27.6161H90.4533C93.3656 27.6161 94.3835 26.7993 94.3835 24.4898V21.0254C94.3552 18.6877 93.3656 17.899 90.4533 17.899Z" />
                
                <path className={styles.letter} d="M102.754 15.167H116.722V17.899H105.808V25.3065H115.93V28.0386H105.808V36.0657H116.75V38.7978H102.754V15.167Z" />
                
                <path className={styles.letter} d="M125.372 18.6877V38.7978H122.432V15.167H126.645L136.965 35.3898V15.167H139.877V38.7978H135.664L125.372 18.6877Z" />
                
                <path className={styles.letter} d="M166.4 38.7978H160.83L159.557 34.1787H151.612L150.34 38.7978H144.77L152.008 15.167H159.133L166.4 38.7978ZM152.828 29.7567H158.285L155.571 19.8425L152.828 29.7567Z" />

                <path className={styles.letter} d="M180.115 38.7978H172.481L165.779 15.167H171.265L176.298 33.9815L181.331 15.167H186.816L180.115 38.7978Z" />
                <path className={styles.letter} d="M195.636 23.2786V38.826H190.547V15.167H197.107L204.911 31.4466V15.167H210V38.7978H203.412L195.636 23.2786Z" />
            </g>
        </svg>
    )
};

export default LogoAnimated;